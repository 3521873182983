<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <QAreaPopup v-if="pop.isShowQArea" @close="pop.isShowQArea=false"></QAreaPopup>
        <CarrotTitle title="E.R (Execution Report)" class="non-print"> 
            <button class="btn-info ml-230">info</button>
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="pop.doPrint" class="btn-default">인쇄하기</button>
                <button @click="pop.showQArea" class="btn-default ml-20">Q영역 설명보기</button>
            </div>
        </CarrotTitle>

        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="progress-area">
                        <div class="legend-box">
                            <span class="dot complete"></span>
                            <span class="txt">완료</span>
                            <span class="dot Proceeding"></span>
                            <span class="txt">진행</span>
                            <span class="dot"></span>
                            <span class="txt">보류/중지/기타</span>
                        </div>
                        <div class="progress-box">
                            <span class="text">{{ view.info.team_name?view.info.team_name:view.info.office_name }} {{ view.info.ename }} ({{ view.info.kname }} )</span>
                            <div class="progress">
                                <div class="bar w-100per">
                                    <span v-if="view.info.cnt_etc>0" class="txt">{{ view.info.cnt_etc }}</span>
                                </div>
                                <div class="bar Proceeding" :style="{ 'width':(view.info.per_done+view.info.per_proc)+'%' }">
                                    <span class="txt">{{ view.info.cnt_proc }} </span>
                                </div>
                                <div class="bar complete" :style="{ 'width':view.info.per_done+'%' }">
                                    <span class="txt">{{ view.info.cnt_done }} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-40 mb-20 txt-center">
                        <!-- <div class="day-select mg-0 dp-inblock"><button @click="view.movePrev" class="btn btn-prev">prev</button></div> -->
                        <!-- <carrot-date-picker v-model="view.wdate" class="w-110px ml-10 mr-10 dp-inblock"></carrot-date-picker> -->
                        <!-- <div class="day-select mg-0 dp-inblock"><button @click="view.moveNext" class="btn btn-next">next</button></div> -->
                        {{ view.wdate }}
                    </div>
                    
                    <table class="table-col"><!-- 행 이동 가능시 : table-move 추가 -->
                        <colgroup>
                            <col width="60">
                            <col width="80">
                            <col width="">
                            <col width="60">
                            <col width="92">
                            <col width="92">
                            <col width="60">
                            <col width="60">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Q영역</th>
                                <th>구분</th>
                                <th>상세</th>
                                <th>진척율</th>
                                <th>시작일</th>
                                <th>완료일</th>
                                <th>기간(일)</th>
                                <th>변경<br>차수</th>
                                <th>Pending<br>Matter</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.info.contents" :key="i">
                                <td>{{ irow.qtype }}</td>
                                <td>{{ irow.ctype }}</td>
                                <td class="preline txt-left">{{ irow.detail }}</td>
                                <td>{{ irow.per_proc_txt }}</td>
                                <td>{{ irow.sdate }}</td>
                                <td>{{ irow.edate }}</td>
                                <td>{{ irow.period }}일</td>
                                <td>{{ irow.cnt_edit }}회</td>
                                <td class="preline txt-left">{{ irow.pendingmatter }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-40 mb-20 dp-table w-100per">
                        <div class="block-title float-left mt-10">이슈사항</div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="1050">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>고객사</th>
                                <th>이슈내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.info.issues" :key="i">
                                <td>{{ irow.company_kname }}</td>
                                <td>{{ irow.contents }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="view.doCancel" class="btn-default float-left mt-30">목록</button>
                    <button v-if="view.info.is_owner=='Y'&&view.info.is_last=='Y'" @click="view.doModify" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>



                    <div class="mt-40 comment-list">
                        <div class="title-box">댓글달기</div>
                        <div class="add-box">
                            <textarea placeholder="댓글을 남겨주세요." v-model="cmt.newtext" class="h-100px"></textarea>
                            <button class="btn" @click="cmt.addCmt(0)">등록</button>
                        </div>

                        <div class="list-box">
                            <!-- 샘플 -->
                            <div class="reply" v-for="(irow, i) in cmt.list" :key="i" :class="cmt.bindDetph(irow.depth)">
                                <div class="list">
                                    <div class="photobox">
                                        <img class="my-profile" :src="irow.photoimg" :alt="irow.ename + '\'s profile'">
                                    </div>
                                    <div class="textbox">
                                        <div class="nameDay">
                                            <span class="name" v-if="irow.kname">{{ irow.ename }}({{ irow.kname }})</span>
                                            <span class="day">{{ irow.wdate }}</span>
                                        </div>
                                        <div class="text preline">{{ irow.content }}</div>
                                        <div class="text" v-if="irow.is_mode=='A'">
                                            <div class="add-box mt-10">
                                                <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext" class="h-100px"></textarea>
                                                <button class="btn type1" @click="cmt.addCmt(irow.idx)">등록</button>
                                            </div>
                                        </div>
                                        <div class="text" v-if="irow.is_mode=='M'">
                                            <div class="add-box mt-10">
                                                <textarea placeholder="댓글을 남겨주세요." v-model="cmt.modtext" class="h-100px"></textarea>
                                                <button class="btn type1" @click="cmt.modCmt(irow.idx)">수정</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btnbox" v-if="irow.depth<5">
                                        <!-- <button v-if="irow.is_writer=='N'" class="btn-add" @click="cmt.showAddCmt(i)">댓글달기</button> -->
                                        <button v-if="irow.is_writer=='Y'" class="btn-edit" @click="cmt.showModCmt(i)">수정</button>
                                        <button v-if="irow.is_writer=='Y'" class="btn-del" @click="cmt.delCmt(i)">삭제</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
// import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import QAreaPopup from '@/components/popup/BusinessManagement/QAreaPopup.vue'


export default {
    layout:"BusinessManagement",
    components: {
        // CarrotDatePicker,
        QAreaPopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            isShowQArea    : false,

            showQArea : () => {
                pop.isShowQArea = true;
            },

            doPrint : () => {
                window.print();
            },
        });

        const view = reactive({
            is_loaded  : false,
            ctype_list : [],

            idx  : 0,
            info : { },

            getCtypeList : () => {
                let params = {};
                axios.get("/rest/businessmgr/getCtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.ctype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            // movePrev : () => {
            //     let cur = view.wdate.split("-");
            //     let td = new Date(cur[0], (Number(cur[1])-1), (Number(cur[2])-1));
            //     view.wdate = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1)) + '-' + (td.getDate()>9?td.getDate():'0'+(td.getDate()));
            //     view.doSearch();
            // },

            // moveNext : () => {
            //     let cur = view.wdate.split("-");
            //     let td = new Date(cur[0], (Number(cur[1])-1), (Number(cur[2])+1));
            //     view.wdate = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1)) + '-' + (td.getDate()>9?td.getDate():'0'+(td.getDate()));
            //     view.doSearch();
            // },

            doCancel : () => {
                router.push({
                    name : 'BusinessManagement-ERList'
                });
            },

            doModify : () => {
                router.push({
                    name : 'BusinessManagement-ERMod-idx',
                    params : {
                        idx : view.idx
                    }
                });
            },

            doSearch : () => {
                let params = { 
                    idx   : view.idx,
                    wdate : view.wdate
                };

                axios.get("/rest/businessmgr/getERInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        if( !view.wdate ){
                            view.wdate = view.info.wdate;
                        }

                        view.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const cmt = reactive({
            page : 1,
            newtext:"", modtext:"",
            list : [], total : 0,

            getList: () => {
                let params = {
                    idx  : view.idx,
                    page : cmt.page
                };
                axios.get("/rest/businessmgr/erCmtList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.list  = res.data.list;
                        cmt.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            bindDetph: (d) => {
                return 'd-' + d;
            },

            addCmt: (idx_parent) => {
                let params = {
                    idx        : view.idx,
                    comment    : idx_parent>0?cmt.modtext:cmt.newtext,
                    idx_parent : idx_parent
                };
                axios.post("/rest/businessmgr/erCmtAdd", params).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.newtext  = "";
                        view.info.cnt_comment = res.data.cnt_comment;
                        cmt.getList();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showAddCmt: (index) => {
                for(let i=0; i<cmt.list.length; i++){
                    cmt.list[i].is_mode = '';
                }
                cmt.modtext  = "";
                cmt.list[index].is_mode = 'A';
            },

            modCmt: (idx) => {
                let params = {
                    idx        : idx,
                    comment    : cmt.modtext
                };
                axios.post("/rest/businessmgr/erCmtMod", params).then((res) => {
                    if( res.data.err == 0 ){
                        cmt.modtext  = "";
                        cmt.getList();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showModCmt: (i) => {
                for(let i=0; i<cmt.list.length; i++){
                    cmt.list[i].is_mode = '';
                }
                cmt.list[i].is_mode = 'M';
                cmt.modtext  = cmt.list[i].content;
            },

            delCmt: (i) => {
                let params = {
                    idx  : cmt.list[i].idx
                };
                axios.post("/rest/businessmgr/erCmtDel", params).then((res) => {
                    if( res.data.err == 0 ){
                        view.info.cnt_comment = res.data.cnt_comment;
                        cmt.getList();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.getCtypeList();
            view.doSearch();
            cmt.getList();
        });

        return {pop, view, cmt};
    }
}
</script>

<style lang="scss" scoped>
.hide-calendar {
    position:absolute; top:0; left:0px; right:0; bottom:0; width:160px; height:23px; overflow:hidden; margin:auto; opacity:0.01;
    .input { display:none;}
}
</style>